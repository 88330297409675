var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common_tree_box" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          class: _vm.tagsView ? "common_tree_handle" : "common_tree_notag",
        },
        [
          _vm.titleShow
            ? _c(
                "el-header",
                {
                  ref: "elHeader",
                  staticClass: "flex-container flex-align-c header",
                  attrs: { height: "46px" },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "flex-container flex-align-c header-row" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "flex-container flex-align-c",
                          attrs: { span: "18" },
                        },
                        [
                          _vm.showTopTip
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      _vm.treeTitle ||
                                      this.$t("cip.cmn.title.typeTitle"),
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("label", { staticClass: "typeTitle" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.treeTitle ||
                                          _vm.$t("cip.cmn.title.typeTitle")
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _c("label", { staticClass: "typeTitle" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.treeTitle ||
                                      _vm.$t("cip.cmn.title.typeTitle")
                                  )
                                ),
                              ]),
                        ],
                        1
                      ),
                      _vm.isShowdig
                        ? _c(
                            "el-col",
                            { attrs: { span: "6" } },
                            [
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      width: "40",
                                      "popper-class": "treePopperClass",
                                    },
                                    model: {
                                      value: _vm.visible,
                                      callback: function ($$v) {
                                        _vm.visible = $$v
                                      },
                                      expression: "visible",
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "popv_p",
                                        on: {
                                          click: function ($event) {
                                            return _vm.add()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("cip.cmn.btn.addBtn"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "popv_p",
                                        on: {
                                          click: function ($event) {
                                            return _vm.edit()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("cip.cmn.btn.editBtn"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "popv_p",
                                        on: {
                                          click: function ($event) {
                                            return _vm.getdelete()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("cip.cmn.btn.delBtn"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text",
                                        },
                                        slot: "reference",
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-setting",
                                          staticStyle: {
                                            "font-size": "18px",
                                            color: "black",
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isShowSearchInput
            ? _c(
                "el-row",
                {
                  staticStyle: {
                    padding: "14px 12px",
                    background: "#FFFFFF",
                    position: "relative",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "searchInput",
                    class: _vm.popoverShow ? "inputWidth" : "",
                    attrs: {
                      size: "small",
                      placeholder: _vm.$t(
                        "cip.cmn.msg.warning.filterateWarning"
                      ),
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _vm.popoverShow
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "80",
                            trigger: "hover",
                          },
                        },
                        [
                          _vm.treeExpand == false && _vm.expandShow
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "font-size": "14px" },
                                  attrs: { type: "text", size: "mini" },
                                  on: { click: _vm.openAll },
                                },
                                [_vm._v("展开全部\n        ")]
                              )
                            : _vm._e(),
                          _vm.treeExpand == true && _vm.expandShow
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "font-size": "14px" },
                                  attrs: { type: "text", size: "mini" },
                                  on: { click: _vm.openAll },
                                },
                                [_vm._v("收缩全部\n        ")]
                              )
                            : _vm._e(),
                          _vm.isInclude == false
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0",
                                    "font-size": "14px",
                                  },
                                  attrs: { type: "text", size: "mini" },
                                  on: { click: _vm.includeDown },
                                },
                                [_vm._v("包含下级\n        ")]
                              )
                            : _vm._e(),
                          _vm.isInclude == true
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0",
                                    "font-size": "14px",
                                  },
                                  attrs: { type: "text", size: "mini" },
                                  on: { click: _vm.includeDown },
                                },
                                [_vm._v("不包含下级\n        ")]
                              )
                            : _vm._e(),
                          _c("el-button", {
                            staticClass: "moreBtn",
                            attrs: {
                              slot: "reference",
                              type: "primary",
                              icon: "el-icon-more",
                            },
                            slot: "reference",
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("el-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "commonTree",
            attrs: {
              data: _vm.treeChangeData,
              props: _vm.defaultProps,
              lazy: _vm.lazy,
              load: _vm.loadNode,
              "default-expand-all": _vm.treeExpand,
              "highlight-current": true,
              "show-checkbox": _vm.showCheckbox,
              "check-strictly": _vm.checkStrictly,
              "default-expanded-keys": _vm.defaultExpandedKeys,
              "expand-on-click-node": false,
              "default-checked-keys": _vm.defaultCheckedKeys,
              "current-node-key": _vm.currentNodeKey,
              "node-key": _vm.nodeKey,
              "filter-node-method": _vm.filterNode,
            },
            on: {
              "node-click": _vm.nodeClick,
              "check-change": _vm.handleCheckChange,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ node, data }) {
                  return _c(
                    "span",
                    { staticClass: "flex-container flex-align-c" },
                    [
                      _c("i", { class: _vm.icon(data) }),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            disabled:
                              node.label.length > 16 - node.level
                                ? false
                                : true,
                            content: node.label,
                            placement: "bottom",
                            effect: "light",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "treeLabel",
                              class: _vm.issuanceStatus[data.issuanceStatus],
                              style: {
                                width: 200 - (node.level - 1) * 18 + "px",
                              },
                            },
                            [_vm._v(_vm._s(node.label))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.isZoom
        ? _c("el-button", {
            staticClass: "rightBtn",
            class: !_vm.show ? "rightBtn1" : "",
            attrs: {
              circle: "",
              type: "primary",
              icon: _vm.show ? "el-icon-arrow-right" : "el-icon-arrow-left",
            },
            on: {
              click: function ($event) {
                return _vm.click_arrow()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }